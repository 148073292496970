import { gql, useQuery } from "@apollo/client";

const GET_INSPECTION_SERVICE_ORDERS = gql`
  query GetInspectionServiceOrders(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: InspectionServiceOrderStatus
    $statuses: [InspectionServiceOrderStatus]
    $category: InspectionServiceOrderCategory
    $priority: InspectionServiceOrderPriority
    $assigner: ID
    $approver: ID
    $assignee: ID
    $fromDate: Date
    $toDate: Date
  ) {
    rows: getInspectionServiceOrders(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      assigner {
          ... on User {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on PartnerUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on ContractorUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on MeterContractorUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
      }
      approver {
          ... on User {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on PartnerUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on ContractorUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on MeterContractorUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
      }
      assignee {
          ... on User {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on PartnerUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on ContractorUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
          ... on MeterContractorUser {
              _id
              code
              lastName
              firstName
              ghanaCardNumber
              phoneNumber
              emailAddress
              profileImageUrl
          }
      }
      category
      status
      priority
     
      estimatedResolutionDate
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      createdAt
      updatedAt
      result
    }
    count: getInspectionServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      category: $category
      priority: $priority
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

export const useInspectionServiceOrders = (variables: any) => {
  const { data, ...rest } = useQuery(GET_INSPECTION_SERVICE_ORDERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const inspectionServiceOrders = data?.rows || [];
  const count = data?.count || 0;
  return { inspectionServiceOrders, count, ...rest };
};
