import {
  CheckIcon,
  ClipboardDocumentListIcon,
  PlayIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import { FC } from "react";
import { classNames } from "utils";

interface InstallationServiceOrderHistoryViewProps {
  installationServiceOrder: {
    code: string;
    history: {
      actorType: "Contractor" | "ContractorUser" | "User"
      actor: any;
      assignee: any
      timestamp: string;
      notes: string
      action:
      "AssignToMeterInstaller" |
      "ReassignToMeterInstaller" |
      "Start" |
      "Resolve" |
      "Approve" |
      "Reject" |
      "AssignToFarmingOutContractor" |
      "ReassignToFarmingOutContractor" |
      "AssignToMeterContractor" |
      "ReassignToMeterContractor"
    }[];
    assigner: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    assignee: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    approver: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    createdAt: string;
    updatedAt: string;
  };
}

const InstallationServiceOrderHistoryView: FC<
  InstallationServiceOrderHistoryViewProps
> = ({ installationServiceOrder }) => {
  const actionIconMap = {
    AssignToMeterInstaller: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    ReassignToMeterInstaller: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    AssignToFarmingOutContractor: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    ReassignToFarmingOutContractor: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    AssignToMeterContractor: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    ReassignToMeterContractor: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <UserPlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    Approve: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    Reject: (
      <span
        className={classNames(
          "bg-red-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    Start: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <PlayIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    Resolve: (
      <span
        className={classNames(
          "bg-green-500",
          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
        )}
      >
        <ClipboardDocumentListIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    ),
  };

  const renderActionDetails = (
    action: InstallationServiceOrderHistoryViewProps["installationServiceOrder"]["history"][number]
  ) => {
    switch (action.action) {
      case "AssignToMeterInstaller":
      case "AssignToFarmingOutContractor":
      case "AssignToMeterContractor":
        {
          return (
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray-900">
                {action.actor?.lastName} {action.actor?.firstName}
              </span>{" "}
              assigned service request to{" "}
              <span className="font-medium text-gray-900">
                {action.assignee?.lastName} {action.actor?.firstName}
              </span>
            </p>
          );
        }
      case "ReassignToMeterInstaller":
      case "ReassignToFarmingOutContractor":
      case "ReassignToMeterContractor":
        {
          return (
            <p className="text-sm text-gray-500">
              <span className="font-medium text-gray-900">
                {action.actor?.lastName} {action.actor?.firstName}
              </span>{" "}
              reassigned service request to{" "}
              <span className="font-medium text-gray-900">
                {action.assignee?.lastName} {action.actor?.firstName}
              </span>
            </p>
          );
        }
      case "Approve": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {action.actor?.lastName} {action.actor?.firstName}
            </span>{" "}
            approved service order
          </p>
        );
      }
      case "Reject": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {action.actor?.lastName} {action.actor?.firstName}
            </span>{" "}
            rejected service order
          </p>
        );
      }
      case "Start": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {action.actor?.lastName} {action.actor?.firstName}
            </span>{" "}
            started service request
          </p>
        );
      }
      case "Resolve": {
        return (
          <p className="text-sm text-gray-500">
            <span className="font-medium text-gray-900">
              {action.actor?.lastName} {action.actor?.firstName}
            </span>{" "}
            resolved service request
          </p>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className="flow-root">
      {/* <h2>Hello</h2> */}
      <ul className="-mb-8">
        {installationServiceOrder?.history?.map(
          (historyItem, historyItemIdx) => (
            <li key={historyItemIdx}>
              <div className="relative pb-8">
                {historyItemIdx !==
                  installationServiceOrder.history.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>{actionIconMap[historyItem.action]}</div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                    <div className="flex-1">
                      {renderActionDetails(historyItem)}
                      {historyItem?.notes && (
                        <div className="relative p-4 border border-gray-300 rounded mt-6">
                          <span className="absolute bg-white px-2 -top-2 text-xs">
                            Notes
                          </span>
                          <p className="text-sm text-gray-600 font-light">
                            {historyItem.notes}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="whitespace-nowrap text-right text-sm flex flex-col text-gray-500">
                      <time dateTime={historyItem.timestamp}>
                        {moment(historyItem.timestamp).format("DD/MM/YYYY")}
                      </time>
                      <time dateTime={historyItem.timestamp}>
                        {moment(historyItem.timestamp).format("hh:mm A")}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default InstallationServiceOrderHistoryView;
