import { gql, useQuery } from "@apollo/client";
import { Shimmers, StatsPageLoader } from "components";
import CustomerFilter from "components/layouts/filter";
import TableBodyComponent from "components/tables/table-body";
import lodash from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC, useMemo } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { getPeriod, useUpdatedSummaryData } from "utils";

const DATE_FORMAT = "YYYY-MM-DD";

const SUMMARY = gql`
  query GetOverallSummary(
    $fromDate: Date
    $toDate: Date
    $region: ID
    $district: ID
    $categories: [String]
    $contractStatuses: [String]
    $serviceTypes: [String]
    $serviceClasses: [String]
    $customerTypes: [String]
    $tariffClasses: [String]
    $meterSystems: [String]
    $meterBrands: [String]
    $type: String
  ) {
    rows: getOverallSummary(
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
      categories: $categories
      contractStatuses: $contractStatuses
      serviceTypes: $serviceTypes
      serviceClasses: $serviceClasses
      customerTypes: $customerTypes
      tariffClasses: $tariffClasses
      meterSystems: $meterSystems
      meterBrands: $meterBrands
      type: $type
    ) {
      _id
      label
      data
    }
  }
`;

const CustomersServiceTypesPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();
  
  const filter = useMemo(
    () => ({
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
      type: "category",
      ...searchParams?.filter,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus } = useQuery(SUMMARY, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const { records, totals } = useUpdatedSummaryData(data);

  const stats = useMemo(
    () => [
      {
        id: "existing",
        name: "Existing",
        value: lodash.get(totals, "data.Existing"),
      },
      {
        id: "newService",
        name: "New Service",
        value: lodash.get(totals, "data.NewService"),
      },
      {
        id: "lateSetup",
        name: "Late Setup",
        value: lodash.sum([
          lodash.get(totals, "data.Shep"),
          lodash.get(totals, "data.Regularization"),
          lodash.get(totals, "data.NotMigrated"),
        ]),
      },
      {
        id: "total",
        name: "Total",
        value: lodash.get(totals, "data.Total"),
      },
    ],
    [totals]
  );

  return (
    <div className="flex-1 flex flex-col mx-auto min-w-0 min-h-full overflow-hidden">
      <CustomerFilter
        filters={[
          "contractStatuses",
          "serviceTypes",
          "serviceClasses",
          "customerTypes",
          "tariffClasses",
          "meterSystems",
        ]}
      />
      {loading ? (
        <StatsPageLoader />
      ) : (
        <div className="flex-1 min-w-0 py-4 sm:py-6 px-6 overflow-hidden overflow-y-auto light flex flex-col">
          <div className="">
            <div className="flex flex-col">
              <h3 className="text-md font-medium leading-6 text-gray-900">
                Summary
              </h3>
              <div className="mt-3 ">
                <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                  {stats.map((item) => (
                    <div
                      key={item.id}
                      className="relative overflow-hidden rounded-lg bg-white p-4 shadow"
                    >
                      <dt>
                        <p className="truncate text-sm font-medium text-gray-500">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="flex justify-between items-end">
                        <p className="text-xl font-light print:font-bold  text-gray-900">
                          {numeral(item.value).format("0,0")}
                        </p>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
          {!searchParams.district && (
            <div className="flex flex-row mt-6 space-x-6">
              <div className="flex-1">
                <div className="flex flex-col">
                  <div className="overflow-y-auto">
                    <TableBodyComponent
                      title={`Customers Per Service Type Per ${
                        searchParams?.region ? "District" : "Region"
                      }`}
                      exportFileName={`Customers By Category For ${getPeriod(searchParams)}`}
                      exportTypes={["EXCEL", "PDF"]}
                      noSpace={true}
                      loading={loading && ![4, 6].includes(networkStatus)}
                      data={{
                        ...records,
                        rows: [
                          ...records.rows,
                          {
                            _id: "total",
                            label: {
                              _id: "total",
                              code: "total",
                              name: "Total",
                            },
                            data: totals.data,
                          },
                        ],
                      }}
                      renderColumns={() => (
                        <>
                          <tr className="hidden print:table-row">
                            <th
                              scope="col"
                              colSpan={8}
                              className="sticky top-0 border-t border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-md font-bold text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              Customers By Category For{" "}
                              {getPeriod(searchParams)}
                            </th>
                          </tr>
                          <tr>
                            <th
                              scope="col"
                              className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            ></th>
                            <th
                              scope="col"
                              className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            ></th>
                            <th
                              scope="col"
                              className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            ></th>
                            <th
                              scope="col"
                              colSpan={4}
                              className="sticky top-0 border-y border-x border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              Late Setup
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            ></th>
                          </tr>
                          <tr>
                            {!!searchParams.region ? (
                              <th
                                scope="col"
                                className="sticky top-0 border-b border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left justify-end text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                              >
                                District
                              </th>
                            ) : (
                              <th
                                scope="col"
                                className="sticky top-0 border-b border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                              >
                                Region
                              </th>
                            )}
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              Existing
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              New Service
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-l border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              Not Migrated
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              Regularization
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              SHEP
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-r border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              Sub Total
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 border-b border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                            >
                              Total
                            </th>
                          </tr>
                        </>
                      )}
                      renderLoader={() => (
                        <tr className="border-t border-gray-200 print:border-gray-700">
                          <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                            <Shimmers.SingleShimmer />
                          </td>
                          <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                            <Shimmers.SingleShimmer />
                          </td>
                          <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                            <Shimmers.SingleShimmer />
                          </td>
                          <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                            <Shimmers.SingleShimmer />
                          </td>
                          <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                            <Shimmers.SingleShimmer />
                          </td>
                        </tr>
                      )}
                      renderItem={(item) => (
                        <tr
                          key={item._id}
                          className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                        >
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                            {item?.label?.name || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                            {numeral(lodash.get(item?.data, "Existing")).format(
                              "0,0"
                            ) || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                            {numeral(
                              lodash.get(item?.data, "NewService")
                            ).format("0,0") || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-l border-gray-200 print:border-gray-700">
                            {numeral(
                              lodash.get(item?.data, "NotMigrated")
                            ).format("0,0") || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                            {numeral(
                              lodash.get(item?.data, "Regularization")
                            ).format("0,0") || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                            {numeral(lodash.get(item?.data, "Shep")).format(
                              "0,0"
                            ) || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-r border-gray-200 print:border-gray-700">
                            {numeral(
                              lodash.sum([
                                lodash.get(item?.data, "NotMigrated"),
                                lodash.get(item?.data, "Regularization"),
                                lodash.get(item?.data, "Shep"),
                              ])
                            ).format("0,0") || "N/A"}
                          </td>
                          <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                            {numeral(lodash.get(item?.data, "Total")).format(
                              "0,0"
                            ) || "N/A"}
                          </td>
                        </tr>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomersServiceTypesPage;
