import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import config from "config";
import _ from "lodash";
import "mapbox-gl/dist/mapbox-gl.css";
import moment from "moment";
import { FC } from "react";
import Map, { MapProvider, Marker } from "react-map-gl";
import { wrapImage } from "utils";

interface ServiceRegularizationViewProps {
  serviceRegularization: {
    code: string;
    category: string;
    customerData: any;
    property: any;
    service: any;
    status: string;
    type: string;
    createdAt: string;
    updatedAt: string;
  };
}

const ServiceRegularizationView: FC<ServiceRegularizationViewProps> = ({
  serviceRegularization,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div className='py-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Service Regularization Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about service request
            </p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className=''>
              <span className='text-xs font-light'>
                Service Regularization Information
              </span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Regularization Code
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.code || "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Regularization Date
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {moment(serviceRegularization?.createdAt).format(
                      dateFormat
                    )}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Regularization Status
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {_.startCase(serviceRegularization?.status) || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='py-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Customer Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about customer</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='pt-6'>
              <span className='text-xs font-light'>User Information</span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Customer Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.customerData?.customerType || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            {serviceRegularization?.customerData?.customerType ===
              "Individual" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>Customer Information</span>
                  <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='col-span-1'>
                      <span className='block text-sm font-light text-gray-700'>
                        Photo
                      </span>
                      <Avatar
                        alt={
                          serviceRegularization?.customerData?.representative
                            .fullName || "N A"
                        }
                        src={
                          serviceRegularization?.customerData?.representative
                            .profileImageUrl || ""
                        }
                        size='lg'
                      />
                    </div>
                    <div className='col-start-1'>
                      <span className='block text-sm font-light text-gray-700'>
                        Title
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.title || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Full Name
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.fullName || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Nationality
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.nationality || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Date of Birth
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(
                          serviceRegularization?.customerData?.representative
                            ?.dateOfBirth
                        ).format(dateFormat)}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Gender
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.gender || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {serviceRegularization?.customerData?.customerType ===
              "Organization" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>
                    Organization Information
                  </span>
                  <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Name
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.organization
                          ?.name || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {serviceRegularization?.customerData?.customerType ===
              "Individual" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>Contact Information</span>
                  <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Phone Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.phoneNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Email Address
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.emailAddress || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {serviceRegularization?.customerData?.customerType ===
              "Organization" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>
                    Representative Information
                  </span>
                  <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='col-start-1'>
                      <span className='block text-sm font-light text-gray-700'>
                        Title
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.title || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Full Name
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.fullName || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Nationality
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.nationality || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Phone Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.phoneNumber || "N/A"}
                      </div>
                    </div>
                    <div className=''>
                      <span className='block text-sm font-light text-gray-700'>
                        Email Address
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.representative
                          ?.emailAddress || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>

      <div className='py-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Customer Identity Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about identity of customer
            </p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            {serviceRegularization?.customerData?.customerType ===
              "Individual" && (
                <>
                  <div className='pt-6'>
                    <span className='text-xs font-light'>
                      Customer Identity Information
                    </span>
                    <div className='grid grid-cols-6 gap-6 mt-2'>
                      <div className='col-span-2'>
                        <span className='block text-sm font-light text-gray-700'>
                          Has Ghana Card
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {serviceRegularization?.customerData?.representative
                            ?.hasGhanaCard
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                      {!serviceRegularization?.customerData?.representative
                        ?.hasGhanaCard && (
                          <div className='col-span-2'>
                            <span className='block text-sm font-light text-gray-700'>
                              ID Type
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                              {_.startCase(
                                serviceRegularization?.customerData?.representative
                                  ?.identityCardType
                              ) || "N/A"}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  {serviceRegularization?.customerData?.representative
                    ?.hasGhanaCard ? (
                    <div className='pt-6'>
                      <span className='text-xs font-light'>
                        Customer Identity Information
                      </span>
                      <div className='grid grid-cols-6 gap-6 mt-2'>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Number
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {serviceRegularization?.customerData?.representative
                              ?.ghanaCardNumber || "N/A"}
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Issue Date
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {moment(
                              serviceRegularization?.customerData?.representative
                                ?.ghanaCardIssueDate
                            ).format(dateFormat)}
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Expiry Date
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {moment(
                              serviceRegularization?.customerData?.representative
                                ?.ghanaCardExpiryDate
                            ).format(dateFormat)}
                          </div>
                        </div>
                        <div className='col-span-3'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Front Image
                          </span>
                          <div className='mt-2'>
                            {wrapImage(
                              <img
                                src={
                                  serviceRegularization?.customerData
                                    ?.representative?.ghanaCardFrontImageUrl
                                }
                                alt={"front"}
                                className='w-full h-64 text-xs'
                              />
                            )}
                          </div>
                        </div>
                        <div className='col-span-3'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Back Image
                          </span>
                          <div className='mt-2'>
                            {wrapImage(
                              <img
                                src={
                                  serviceRegularization?.customerData
                                    ?.representative?.ghanaCardBackImageUrl
                                }
                                alt={"back"}
                                className='w-full h-64 text-xs'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='pt-6'>
                      <span className='text-xs font-light'>
                        Customer Identity Information
                      </span>
                      <div className='grid grid-cols-6 gap-6 mt-2'>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Number
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {serviceRegularization?.customerData?.representative
                              ?.identityCardNumber || "N/A"}
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Issue Date
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {moment(
                              serviceRegularization?.customerData?.representative
                                ?.identityCardIssueDate
                            ).format(dateFormat)}
                          </div>
                        </div>
                        {serviceRegularization?.customerData?.representative
                          ?.identityCardType !== "VotersIdentificationCard" && (
                            <div className='col-span-2'>
                              <span className='block text-sm font-light text-gray-700'>
                                ID Expiry Date
                              </span>
                              <div className='mt-1 block w-full sm:text-sm'>
                                {moment(
                                  serviceRegularization?.customerData
                                    ?.representative?.identityCardExpiryDate
                                ).format(dateFormat)}
                              </div>
                            </div>
                          )}
                        <div className='col-span-3'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Front Image
                          </span>
                          <div className='mt-2'>
                            {wrapImage(
                              <img
                                src={
                                  serviceRegularization?.customerData
                                    ?.representative?.identityCardFrontImageUrl
                                }
                                alt={"front"}
                                className='w-full h-64 text-xs'
                              />
                            )}
                          </div>
                        </div>
                        {serviceRegularization?.customerData?.representative
                          ?.identityCardType === "DriversLicense" && (
                            <div className='col-span-3'>
                              <span className='block text-sm font-light text-gray-700'>
                                ID Back Image
                              </span>
                              <div className='mt-2'>
                                {wrapImage(
                                  <img
                                    src={
                                      serviceRegularization?.customerData
                                        ?.representative?.identityCardBackImageUrl
                                    }
                                    alt={"back"}
                                    className='w-full h-64 text-xs'
                                  />
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </>
              )}
            {serviceRegularization?.customerData?.customerType ===
              "Organization" && (
                <div className='pt-6'>
                  <span className='text-xs font-light'>
                    Organization Identity Information
                  </span>
                  <div className='grid grid-cols-6 gap-6 mt-2'>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Tax Identification Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.organization
                          ?.taxIdentificationNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Organization Registration Number
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {serviceRegularization?.customerData?.organization
                          ?.organizationRegistrationNumber || "N/A"}
                      </div>
                    </div>
                    <div className='col-span-2'>
                      <span className='block text-sm font-light text-gray-700'>
                        Organization Registration Date
                      </span>
                      <div className='mt-1 block w-full sm:text-sm'>
                        {moment(
                          serviceRegularization?.customerData?.organization
                            ?.organizationRegistrationDate
                        ).format(dateFormat)}
                      </div>
                    </div>
                    <div className='col-span-3'>
                      <span className='block text-sm font-light text-gray-700'>
                        Certificate of Incorporation
                      </span>
                      <div className='mt-2'>
                        {wrapImage(
                          <img
                            src={
                              serviceRegularization?.customerData?.organization
                                ?.certificateOfIncorporationDocumentUrl
                            }
                            alt={"Certificate of Incorporation"}
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </div>
                    </div>
                    <div className='col-span-3'>
                      <span className='block text-sm font-light text-gray-700'>
                        Business Regsitration Document
                      </span>
                      <div className='mt-2'>
                        {wrapImage(
                          <img
                            src={
                              serviceRegularization?.customerData?.organization
                                ?.organizationRegistrationDocumentUrl
                            }
                            alt={"Business Registration Document"}
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {serviceRegularization?.customerData?.customerType ===
              "Organization" && (
                <>
                  <div className='pt-6'>
                    <span className='text-xs font-light'>
                      Representative Identity Information
                    </span>
                    <div className='grid grid-cols-6 gap-6 mt-2'>
                      <div className='col-span-2'>
                        <span className='block text-sm font-light text-gray-700'>
                          Has Ghana Card
                        </span>
                        <div className='mt-1 block w-full sm:text-sm'>
                          {serviceRegularization?.customerData?.representative
                            ?.hasGhanaCard
                            ? "Yes"
                            : "No"}
                        </div>
                      </div>
                      {!serviceRegularization?.customerData?.representative
                        ?.hasGhanaCard && (
                          <div className='col-span-2'>
                            <span className='block text-sm font-light text-gray-700'>
                              ID Type
                            </span>
                            <div className='mt-1 block w-full sm:text-sm'>
                              {_.startCase(
                                serviceRegularization?.customerData?.representative
                                  ?.identityCardType
                              ) || "N/A"}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  {serviceRegularization?.customerData?.representative
                    ?.hasGhanaCard ? (
                    <div className='pt-6'>
                      <span className='text-xs font-light'>
                        Representative Identity Information
                      </span>
                      <div className='grid grid-cols-6 gap-6 mt-2'>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Number
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {serviceRegularization?.customerData?.representative
                              ?.ghanaCardNumber || "N/A"}
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Issue Date
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {moment(
                              serviceRegularization?.customerData?.representative
                                ?.ghanaCardIssueDate
                            ).format(dateFormat)}
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Expiry Date
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {moment(
                              serviceRegularization?.customerData?.representative
                                ?.ghanaCardExpiryDate
                            ).format(dateFormat)}
                          </div>
                        </div>
                        <div className='col-span-3'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Front Image
                          </span>
                          <div className='mt-2'>
                            {wrapImage(
                              <img
                                src={
                                  serviceRegularization?.customerData
                                    ?.representative?.ghanaCardFrontImageUrl
                                }
                                alt={"front"}
                                className='w-full h-64 text-xs'
                              />
                            )}
                          </div>
                        </div>
                        <div className='col-span-3'>
                          <span className='block text-sm font-light text-gray-700'>
                            Ghana Card Back Image
                          </span>
                          <div className='mt-2'>
                            {wrapImage(
                              <img
                                src={
                                  serviceRegularization?.customerData
                                    ?.representative?.ghanaCardBackImageUrl
                                }
                                alt={"back"}
                                className='w-full h-64 text-xs'
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='pt-6'>
                      <span className='text-xs font-light'>
                        Representative Identity Information
                      </span>
                      <div className='grid grid-cols-6 gap-6 mt-2'>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Number
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {serviceRegularization?.customerData?.representative
                              ?.identityCardNumber || "N/A"}
                          </div>
                        </div>
                        <div className='col-span-2'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Issue Date
                          </span>
                          <div className='mt-1 block w-full sm:text-sm'>
                            {moment(
                              serviceRegularization?.customerData?.representative
                                ?.identityCardIssueDate
                            ).format(dateFormat)}
                          </div>
                        </div>
                        {serviceRegularization?.customerData?.representative
                          ?.identityCardType !== "VotersIdentificationCard" && (
                            <div className='col-span-2'>
                              <span className='block text-sm font-light text-gray-700'>
                                ID Expiry Date
                              </span>
                              <div className='mt-1 block w-full sm:text-sm'>
                                {moment(
                                  serviceRegularization?.customerData
                                    ?.representative?.identityCardExpiryDate
                                ).format(dateFormat)}
                              </div>
                            </div>
                          )}
                        <div className='col-span-3'>
                          <span className='block text-sm font-light text-gray-700'>
                            ID Front Image
                          </span>
                          <div className='mt-2'>
                            {wrapImage(
                              <img
                                src={
                                  serviceRegularization?.customerData
                                    ?.representative?.identityCardFrontImageUrl
                                }
                                alt={"front"}
                                className='w-full h-64 text-xs'
                              />
                            )}
                          </div>
                        </div>
                        {serviceRegularization?.customerData?.representative
                          ?.identityCardType === "DriversLicense" && (
                            <div className='col-span-3'>
                              <span className='block text-sm font-light text-gray-700'>
                                ID Back Image
                              </span>
                              <div className='mt-2'>
                                {wrapImage(
                                  <img
                                    src={
                                      serviceRegularization?.customerData
                                        ?.representative?.identityCardBackImageUrl
                                    }
                                    alt={"back"}
                                    className='w-full h-64 text-xs'
                                  />
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </>
              )}
          </div>
        </div>
      </div>

      <div className='py-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Property Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>Details about property</p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='pt-6'>
              <span className='text-xs font-light'>Owner Information</span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Full name
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.owner?.fullName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Phone Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.owner?.phoneNumber ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-6'>
              <span className='text-xs font-light'>Property Information</span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Region
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.region?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    District
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.district?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Structure Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.structureNumber || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Ghana Post Address
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.ghanaPostAddress || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Street Name
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.streetName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    House Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.houseNumber || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Community
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.community || "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Closest Landmark
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.landmark || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-6'>
              <span className='text-xs font-light'>Premise Information</span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Premise Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.premiseType?.name ||
                      "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Premise Category
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.premiseCategory?.name ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Activity
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.activity?.name || "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Sub Activity
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.property?.subActivity?.name ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-6 grid grid-cols-2 gap-6'>
              <div>
                <span className='text-xs font-light'>
                  Site Plan Information
                </span>
                {serviceRegularization?.property?.sitePlanDocumentUrl ? (
                  <div className='mt-2'>
                    {wrapImage(
                      <img
                        src={
                          serviceRegularization?.property?.sitePlanDocumentUrl
                        }
                        alt={serviceRegularization?.property?.structureNumber}
                        className='w-full h-64 text-xs'
                      />
                    )}
                  </div>
                ) : (
                  <div className='border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600'>
                    Site plan not provided
                  </div>
                )}
              </div>
              <div>
                <span className='text-xs font-light'>Map Information</span>
                <div className='mt-2 w-full h-64 rounded-md overflow-hidden'>
                  <MapProvider>
                    <Map
                      id='service-location'
                      initialViewState={{
                        longitude:
                          serviceRegularization?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361,
                        latitude:
                          serviceRegularization?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032,
                        zoom: 15,
                      }}
                      mapStyle='mapbox://styles/mapbox/streets-v12'
                      mapboxAccessToken={config.mapbox.token}
                    >
                      <Marker
                        longitude={
                          serviceRegularization?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361
                        }
                        latitude={
                          serviceRegularization?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032
                        }
                      >
                        <img
                          className='w-6 h-6'
                          src={require("assets/logo.png")}
                          alt={"pin"}
                        />
                      </Marker>
                    </Map>
                  </MapProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='py-4'>
        <div className='flex justify-between items-center cursor-pointer'>
          <div>
            <h3 className='text-md leading-6 font-normal text-gray-900 dark:text-gray-100'>
              Service Information
            </h3>
            <p className='mt-1 text-xs text-gray-500'>
              Details about service being requested for
            </p>
          </div>
          <div />
        </div>
        <div className='mt-6'>
          <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
            <div className='pt-6'>
              <span className='text-xs font-light'>Service Information</span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Type
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.service?.serviceType || "N/A"}
                  </div>
                </div>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Service Class
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.service?.serviceClass
                      ? _.startCase(serviceRegularization?.service.serviceClass)
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-6'>
              <span className='text-xs font-light'>
                Energy Certification Information
              </span>
              <div className='grid grid-cols-3 gap-6 mt-2'>
                <div>
                  <span className='block text-sm font-light text-gray-700'>
                    Energy Certificate Number
                  </span>
                  <div className='mt-1 block w-full sm:text-sm'>
                    {serviceRegularization?.service?.energyCertificateNumber ||
                      "N/A"}
                  </div>
                </div>
                <div className='col-span-2'>
                  <span className='block text-sm font-light text-gray-700'>
                    Energy Certificate Document
                  </span>
                  <div className='mt-1 block sm:text-sm'>
                    {wrapImage(
                      <img
                        src={
                          serviceRegularization?.service
                            ?.energyCertificateDocumentUrl
                        }
                        alt={
                          serviceRegularization?.service
                            ?.energyCertificateNumber
                        }
                        className='w-full  h-64 object-cover object-top text-xs'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRegularizationView;
