import { useFormik } from "formik";
import { Link, useNavigate, useSearch } from "react-location";
import * as Yup from 'yup';
import { useLocalStorage } from "react-use";
import { gql, useMutation } from "@apollo/client";
import { setAuth } from "apollo/cache/auth";
import { LocationGenerics } from "router/location";
import { FC } from "react";

const SEND_RESET_CODE = gql`
  mutation SendResetCode($username: String!) {
    sendResetCode(username: $username) {
      user {
        firstName
        profileImageUrl
      }
    }
  }
`;

const VERIFY_RESET_CODE = gql`
  mutation VerifyResetCode($username: String! $passcode: String!){
    verifyResetCode(username: $username passcode: $passcode) {
      user {
        _id
        code
        lastName
        firstName
        gender
        phoneNumber
        ghanaCardNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
          permissions
        }
        region {
          _id
        }
        regionCode
        regionName
        district {
          _id
        }
        districtCode
        districtName
        meta {
          isPasswordSet
          lastLoginAt
        }
        createdAt
      }
      tokens {
        authorization
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetCurrentPassword($newPassword: String!){
    resetCurrentPassword(newPassword: $newPassword) {
      user {
        _id
        code
        lastName
        firstName
        gender
        phoneNumber
        ghanaCardNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
          permissions
        }
        region {
          _id
        }
        regionCode
        regionName
        district {
          _id
        }
        districtCode
        districtName
        meta {
          isPasswordSet
          lastLoginAt
        }
        createdAt
      }
      tokens {
        authorization
      }
    }
  }
`

const ForgotPasswordPage: FC = () => {

  const navigate = useNavigate()
  const search = useSearch<LocationGenerics>()
  const [forgotStore, setForgotStore] = useLocalStorage<{ username?: string, tab: string, token?: string }>("forgot-store", { username: "", tab: "send-code", token: "" });
  const [sendResetCode, { loading: loadingSend }] = useMutation(SEND_RESET_CODE);
  const [verifyResetCode, { loading: loadingVerify }] = useMutation(VERIFY_RESET_CODE);
  const [resetPassword, { loading: loadingReset }] = useMutation(RESET_PASSWORD);
  const sendCodeForm = useFormik({
    initialValues: {
      username: ""
    },
    validationSchema: Yup.object({
      username: Yup.string().email("Invalid email address").required("Email address is required"),
    }),
    onSubmit: async (values) => {
      await sendResetCode({
        variables: {
          username: values.username || forgotStore?.username,
        }
      })
        .then(({ data }) => {
          setForgotStore({ tab: "verify-code", username: values.username || forgotStore?.username })
        })
        .catch((err) => {

        })
    }
  })

  const verifyCodeForm = useFormik({
    initialValues: {
      code: ""
    },
    validationSchema: Yup.object({
      code: Yup.string().min(6, "Code should be 6 digits").required("Enter reset code"),
    }),
    onSubmit: async (values) => {
      await verifyResetCode({
        variables: {
          username: forgotStore?.username,
          passcode: values.code
        }
      })
        .then(({ data }) => {
          setForgotStore({ tab: "reset-password", token: data.verifyResetCode.tokens.authorization, username: data.verifyResetCode.user.firstName })
        })
        .catch((err) => {

        })
    }
  })
  const resetPasswordForm = useFormik({
    initialValues: {
      password: "",
      rPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be more than 8 characters")
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])/,
          "Must Contain at least One Lowercase Character"
        )
        .matches(
          /^(?=.*[A-Z])/,
          "Must Contain at least One Uppercase Character"
        )
        .matches(
          /^(?=.*[0-9])/,
          "Must Contain at least One Number"
        )
        .matches(
          /^(?=.*[!@#$%^&*\\|/{}()<>:;[\]_\\-\\=?])/,
          "Must Contain at least One special case Character"
        ),
      rPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords do not match")
        .required('Please confirm your password')
    }),
    onSubmit: async (values) => {
      await resetPassword({
        variables: {
          newPassword: values.password
        },
        context: {
          headers: {
            authorization: forgotStore?.token
          }
        }
      })
        .then(({ data }) => {
          setAuth({
            user: data?.resetCurrentPassword?.user,
            token: data.resetCurrentPassword.tokens?.authorization,
          })
          setForgotStore({ tab: "send-code", username: "" })
          navigate({
            replace: true,
            to: search?.redirect ?? "/"
          })
        })
        .catch((err) => {

        })
    }
  })

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <div className="flex items-end flex-row">
          <div className="h-16 w-16 flex-shrink-0 p-1 bg-indigo-800">
            <img
              className="h-14 w-14 border-white border p-1"
              src={require("assets/logo.png")}
              alt="SUBS"
            />
          </div>
          <div className="ml-3">
            <span className="text-lg text-primary-600 font-mono">Commercial Services<br />Console</span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{["send-code", "verify-code"].includes((forgotStore as any).tab) ? "Forgot your password?" : "Update your password"}</h2>
        <p className="mt-2 text-sm text-gray-600">
          {forgotStore?.tab === "send-code"
            ? "Enter your email address to receive an email with a verification code."
            : (forgotStore?.tab === "verify-code" ? `Enter the 6 digit code sent to your email address (${forgotStore?.username})`
              : `Hello ${forgotStore?.username}, kindly enter a new password to use with your account`)}
        </p>
      </div>

      <div className="mt-8">

        <div className="mt-6">
          {forgotStore?.tab === "send-code" && (
            <form onSubmit={sendCodeForm.handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="username"
                    name="username"
                    type="email"
                    autoComplete="email"
                    value={sendCodeForm.values.username}
                    onChange={sendCodeForm.handleChange}
                    placeholder="eg. user@polymorphlabs.com"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {sendCodeForm.touched.username && sendCodeForm.errors.username}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                </div>

                <div className="text-sm text-gray-600">
                  Remember your password?{' '}
                  <Link to="/login" className="font-medium text-primary-600 hover:text-primary-500">
                    Login
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loadingSend}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r bg-primary-600  hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loadingSend ? "Sending Reset Code..." : "Send Reset Code"}
                </button>
              </div>
            </form>
          )}
          {forgotStore?.tab === "verify-code" && (
            <form onSubmit={verifyCodeForm.handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Verification Code
                </label>
                <div className="mt-1">
                  <input
                    id="code"
                    name="code"
                    type="text"
                    value={verifyCodeForm.values.code}
                    onChange={verifyCodeForm.handleChange}
                    maxLength={6}
                    minLength={6}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {verifyCodeForm.touched.code && verifyCodeForm.errors.code}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                </div>

                <div className="text-sm text-gray-600">
                  Didn't receive code?{' '}
                  <button type="button" disabled={loadingSend} onClick={() => sendCodeForm.handleSubmit()} className="font-medium text-primary-600 hover:text-primary-500">
                    Resend
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loadingVerify}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loadingVerify ? "Verifying Reset Code..." : "Verify Reset Code"}
                </button>
              </div>
            </form>
          )}
          {forgotStore?.tab === "reset-password" && (
            <form onSubmit={resetPasswordForm.handleSubmit} className="space-y-6">
              <div className="space-y-1">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  New Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={resetPasswordForm.values.password}
                    onChange={resetPasswordForm.handleChange}
                    onBlur={resetPasswordForm.handleBlur}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {resetPasswordForm.touched.password && resetPasswordForm.errors.password}
                </p>
              </div>

              <div className="space-y-1">
                <label htmlFor="r-password" className="block text-sm font-medium text-gray-700">
                  Repeat Password
                </label>
                <div className="mt-1">
                  <input
                    id="rPassword"
                    name="rPassword"
                    type="password"
                    value={resetPasswordForm.values.rPassword}
                    onChange={resetPasswordForm.handleChange}
                    onBlur={resetPasswordForm.handleBlur}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {resetPasswordForm.touched.rPassword && resetPasswordForm.errors.rPassword}
                </p>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loadingReset}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loadingReset ? "Reseting password..." : "Reset password"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordPage;