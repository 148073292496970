import { DistrictCodes } from "apollo/data";
import lodash from "lodash";
import moment from "moment";
import * as Yup from "yup";
export const ServiceTypes = ["Postpaid", "Prepaid"] as const;
export type ServiceType = (typeof ServiceTypes)[number];
export const ServiceClasses = [
  "Residential",
  "NonResidential",
  "Industrial",
] as const;
export type ServiceClass = (typeof ServiceClasses)[number];

export interface IResolveInstallationServiceOrderFormSchema {
  propertyInfo: {
    ghanaPostAddress: string;
    propertyImageUrls: string[];
    networkDesignDrawingUrls: string[];
  };

  serviceInfo: {
    transformerNumber: string;
    meter?: any;
  };

  extraInfo: {
    readingDate: string;
    readingValue: number;
    notes: string;
    appliances: {
      appliance: {
        _id: string;
        code: string;
        name: string;
        wattage: number;
      };
      quantity: number;
    }[];
    materials: {
      material: {
        _id: string;
        code: string;
        name: string;
        quantityUnit: string;
      };
      quantity: number;
    }[];
  };
}

export const PropertyInfoFormSchema = Yup.object().shape({
  ghanaPostAddress: Yup.string()
    .matches(
      new RegExp(
        `/^${lodash.map(DistrictCodes, "code").join("|")}-d{3}-d{4}$/`
      ),
      {
        message: "Invalid Ghana Post Address",
      }
    )
    .required(),
  propertyImageUrls: Yup.array().of(Yup.string().url().required()).min(1).required(),
  networkDesignDrawingUrls: Yup.array()
    .of(Yup.string().url().required())
    .min(1)
    .required(),
});

export const ServiceInfoFormSchema = Yup.object().shape({
  transformerNumber: Yup.string().required(
    "Kindly Indicate Transformer Number"
  ),
  meter: Yup.object().nullable().required("Kindly Indicate Installed Meter"),
});

export const ExtraInfoFormSchema = Yup.object().shape({
  readingDate: Yup.date().max(moment().endOf("day").toDate()).required(),
  readingValue: Yup.number().min(0).required(),
  notes: Yup.string(),
});

export const ResolveInstallationServiceOrderFormSchema = Yup.object().shape({
  propertyInfo: PropertyInfoFormSchema.required(),
  serviceInfo: ServiceInfoFormSchema.required(),
  extraInfo: ExtraInfoFormSchema.required(),
});
