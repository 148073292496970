import OfficeHeader from "components/layouts/office-header";
import { FC, useEffect } from "react";
import { Link, Outlet, useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames, useUrlState } from "utils";

interface HeaderProps {
  renderActions?: FC<{}>;
  noDatePeriod?: boolean;
}

const InstallationServiceOrdersPage: FC<HeaderProps> = ({ renderActions, noDatePeriod }) => {
  const { route } = useMatch<LocationGenerics>();
  const [_tab, setTab] = useUrlState("tab");
  const [, setFilter] = useUrlState("filter");
  const [, setMeterSystem] = useUrlState("meterSystem");

  useEffect(() => {
    if(!_tab) {
      setTab("summary")
    }
    setFilter(undefined)
    setMeterSystem(undefined)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_tab])

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <OfficeHeader renderActions={renderActions} noDatePeriod={noDatePeriod} />
      <div className="block">
        <div className="border-b border-gray-200 bg-white px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            {route?.children?.map((_orderStatus: any) => {
              const tab = _orderStatus.id.replace(`${route.id}/*-`, "")
              return (
                <Link
                  key={_orderStatus?.sidebar?.label}
                  search={(old) => ({ ...old, tab })}
                  className={classNames(
                    tab ===_tab ? "border-primary-500 text-primary-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm"
              )}
                >
                  {_orderStatus?.sidebar?.label}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="flex flex-1 overflow-y-auto">
        <Outlet />
      </div>
    </main>
  );
};

export default InstallationServiceOrdersPage;
