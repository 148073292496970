import { DistrictCodes, ServiceClasses, ServiceTypes } from "apollo/data";
import lodash from "lodash";
import * as Yup from "yup";
export type ServiceType = (typeof ServiceTypes)[number]["value"];
export type ServiceClass = (typeof ServiceClasses)[number]["value"];

export interface IResolveInspectionServiceOrderFormSchema {
  propertyInfo: {
    region: {
      _id: string;
      code: string;
      name: string;
    } | null;
    district: {
      _id: string;
      code: string;
      name: string;
    } | null;

    ghanaPostAddress: string;
    propertyImageUrls: string[];
    premiseType: {
      _id: string;
      code: string;
      name: string;
    } | null;
    premiseCategory: {
      _id: string;
      code: string;
      name: string;
    } | null;
    activity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    subActivity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    sitePlanDocumentUrl: string;
    networkDesignDrawingUrls: string[];
  };
  serviceInfo: {
    tariffClass: {
      _id: string;
      code: string;
      name: string;
    } | null;
    loadType: "HV" | "LV";
    contractedDemand: number;
    closestTransformerNumber: string;
    closestFeederNumber: string;
    closestFeederLoad: number;
    closestFeederVoltage: number;
    ctMeterRatio: number;
    transformerTestResultDocumentUrl: string;
  };
  extraInfo: {
    // appliances: {
    //   appliance: {
    //     _id: string;
    //     code: string;
    //     name: string;
    //     wattage: number;
    //   };
    //   quantity: number;
    // }[];
    billOfLadingDocumentUrl: string;
    customerMaterialsReceiptDocumentUrl: string;
    customerMaterials: {
      material: string;
      unitCost: number;
      quantity: number;
      cost: number;
    }[];
    installationMaterials: {
      material: string;
      unitCost: number;
      quantity: number;
      cost: number;
    }[];
    installationCharges: {
      title: string;
      unitCost: number;
      quantity: number;
      cost: number;
    }[];
    notes: string;
  };
}

export const PropertyInfoFormSchema = Yup.object().shape({
  region: Yup.object().nullable().required("Kindly select a region"),
  district: Yup.object().nullable().required("Kindly select a district"),
  ghanaPostAddress: Yup.string()
    .matches(
      new RegExp(
        `/^${lodash.map(DistrictCodes, "code").join("|")}-d{3}-d{4}$/`
      ),
      {
        message: "Invalid Ghana Post Address",
      }
    )
    .required(),
  propertyImageUrls: Yup.array().of(Yup.string().url().required()).required(),
  premiseType: Yup.object().nullable().required("Kindly select a premise type"),
  premiseCategory: Yup.object()
    .nullable()
    .required("Kindly select a premise category"),
  activity: Yup.object().nullable().required("Kindly select an activity"),
  subActivity: Yup.object().nullable().required("Kindly select a sub activity"),
  sitePlanDocumentUrl: Yup.string().url().notRequired(), //"Kindly upload site plan document"),
  networkDesignDrawingUrls: Yup.array()
    .of(Yup.string().url().required())
    .notRequired(),
});

export const ServiceInfoFormSchema = Yup.object().shape({
  tariffClass: Yup.object().nullable().required("Kindly select a tariff class"),
  loadType: Yup.string().oneOf(["HV", "LV"]).required(),
  contractedDemand: Yup.number().positive(),
  closestTransformerNumber: Yup.string().required(),
  closestFeederNumber: Yup.string().required(),
  closestFeederLoad: Yup.number().positive(),
  closestFeederVoltage: Yup.number().positive(),
  transformerTestResultDocumentUrl: Yup.string().url().notRequired(), //"Kindly upload site plan document"),
});

export const ExtraInfoFormSchema = Yup.object().shape({
  billOfLadingDocumentUrl: Yup.string().url().notRequired(), //"Kindly upload site plan document"),
  customerMaterialsReceiptDocumentUrl: Yup.string().url().notRequired(), //"Kindly upload site plan document"),
  // appliances: Yup.array()
  //   .of(
  //     Yup.object()
  //       .shape({
  //         appliance: Yup.object()
  //           .nullable()
  //           .required("Kindly select an appliance"),
  //         quantity: Yup.number().min(0).required(),
  //       })
  //       .required()
  //   )
  //   .required(),
  customerMaterials: Yup.array()
    .of(
      Yup.object()
        .shape({
          material: Yup.string().required("Kindly enter a material"),
          unitCost: Yup.number().min(0).required(),
          quantity: Yup.number().min(0).required(),
        })
        .required()
    )
    .required(),
  installationMaterials: Yup.array()
    .of(
      Yup.object()
        .shape({
          material: Yup.string().required("Kindly enter a material"),
          unitCost: Yup.number().min(0).required(),
          quantity: Yup.number().min(0).required(),
        })
        .required()
    )
    .required(),
  installationCharges: Yup.array()
    .of(
      Yup.object()
        .shape({
          title: Yup.string().required("Kindly enter a title"),
          unitCost: Yup.number().min(0).required(),
          quantity: Yup.number().min(0).required(),
        })
        .required()
    )
    .required(),
  notes: Yup.string(),
});

export const ResolveInspectionServiceOrderFormSchema = Yup.object().shape({
  propertyInfo: PropertyInfoFormSchema.required(),
  serviceInfo: ServiceInfoFormSchema.required(),
  extraInfo: ExtraInfoFormSchema.required(),
});
