import { useReactiveVar } from "@apollo/client";
import { Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, useTableData } from "utils";
import config from "config";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import { useComplaints } from "./hooks";
import ViewComplaintContainer from "./view";
const DATE_FORMAT = "YYYY-MM-DD";

const ComplaintsPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
      ],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
      status: searchParams.orderStatus || undefined,
    }),
    [searchParams]
  );

  const onSearch = (searchValue: string, searchField: string) => {
    refetch({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: searchValue.toString(),
      searchFields: filter.searchFields,
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
      status: searchParams.orderStatus || undefined,
    });
  };

  const { complaints, count, loading, networkStatus, refetch } =
    useComplaints(filter, searchParams);

  const records = useTableData({ rows: complaints, count });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <>
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"complaints"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading}
            data={records}
            hasSearch={true}
            onSearchSubmit={onSearch}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer | SPN
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact Person
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Scope | Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.ActionsShimmer actionsCount={4} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.servicePoint?.customerOrganization?.name ||
                      item?.servicePoint?.customerRepresentative?.fullName ||
                      "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.servicePoint?.code || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.category === "Premium"
                        ? `bg-primary-100 text-primary-800`
                        : "",
                      item?.category === "Standard"
                        ? `bg-gray-100 text-gray-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.category || "Unknown"}</span>
                  </span>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.scope || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.type?.name || "N/A"}
                  </div>
                </td>

                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
      {!!searchParams.id?.length && (
        <ViewComplaintContainer
          open={modal === "view"}
          setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
        />
      )}
    </>
  );
};

export default ComplaintsPage;
