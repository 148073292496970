import { gql, useQuery } from "@apollo/client";

const GET_COMPLAINTS = gql`
  query GetComplaints(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $region: ID
    $district: ID
    $fromDate: Date
    $toDate: Date
    $status: ComplaintStatus
  ) {
    rows: getComplaints(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
      status: $status
    ) {
      _id
      code
      description
      createdAt
      updatedAt
      createdBy {
        ... on User {
          code
          firstName
          lastName
          profileImageUrl
        }
        ... on PartnerUser {
            code
            firstName
            lastName
            profileImageUrl
        }
      }
      category
      meter {
        code
        status
      }
      servicePoint {
        _id
        code
        customerOrganization {
          name
        }
        customerRepresentative {
          fullName
          phoneNumber
        }
      }
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
      type {
          _id
          name
      }
    }
    count: getComplaintsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
      status: $status
    )
  }
`;

export const useComplaints = (variables: any, searchParams?: any) => {
  const { data, ...rest } = useQuery(GET_COMPLAINTS, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  return {
    complaints: data?.rows || [],
    count: data?.count || 0,
    summary: null,
    ...rest,
  };
};
