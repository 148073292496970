import { FC } from "react";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import moment from "moment";
import { Parser } from 'html-to-react'

interface ComplaintViewProps {
  complaint: any;
}

const ComplaintView: FC<ComplaintViewProps> = ({ complaint }) => {
  const {dateFormat} = useReactiveVar(currentConfigVar)
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Complaint Details</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Status
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(complaint?.status) || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Created At</span>
            <div className="mt-1 block w-full sm:text-sm">
              {moment(complaint?.createdAt).format(dateFormat) || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Resolved At</span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.completedAt ? moment(complaint?.completedAt).format(dateFormat) : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Category
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(complaint?.category) || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Scope
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(complaint?.scope) || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">Type</span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(complaint?.type?.name) || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Complaint Description</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div className="col-span-4">
            <span className="block text-sm font-light text-gray-700">
              Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.description ? Parser().parse(complaint?.description) : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Supporting Documents
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.contactPerson?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Person Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.contactPerson?.fullName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.contactPerson?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.contactPerson?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Created By</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {[
                complaint?.createdBy?.firstName,
                complaint?.createdBy?.lastName,
              ].join(" ").trim() || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.createdBy?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {complaint?.createdBy?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintView;
