import { classNames, wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { ComplaintView, ServicePointView, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useState } from "react";

export const GET_COMPLAINT = gql`
  query GetComplaint($id: ID!) {
    complaint: getComplaint(id: $id) {
      _id
      code
      category
      status
      type {
          name
      }
      description
      servicePointCode
      customerCode
      accountCode
      geoCode
      contractStatus
      meterCode
      createdAt
      updatedAt
      contactPerson {
        emailAddress
        fullName
        phoneNumber
      }
      createdBy {
        ... on User {
          code
          firstName
          lastName
          profileImageUrl
          emailAddress
          phoneNumber
        }
        ... on PartnerUser {
            code
            firstName
            lastName
            profileImageUrl
            emailAddress
            phoneNumber
        }
      }
      servicePoint {
        _id
        code
        qrCode
        propertyCode
        customerCode
        accountCode
        meterCode
        serviceType
        serviceClass
        geoCode
        meterLocation
        transformerNumber
        contractedDemand
        status
        customerType
        customer {
          _id
          representative {
            title
            fullName
            phoneNumber
            emailAddress
            profileImageUrl
            ghanaCardNumber
            ghanaCardIssueDate
            ghanaCardExpiryDate
            ghanaCardFrontImageUrl
            ghanaCardBackImageUrl
            identityCardNumber
            identityCardType
            identityCardIssueDate
            identityCardExpiryDate
            identityCardFrontImageUrl
            identityCardBackImageUrl
          }
          organization {
            name
            taxIdentificationNumber
            organizationRegistrationNumber
            organizationRegistrationDate
            certificateOfIncorporationDocumentUrl
            organizationRegistrationDocumentUrl
          }
          customerType
          code
        }
        meta {
          lastReadingDate
          lastBillAmount
          lastBillDate
          lastReadingValue
        }
        tariffClass {
          _id
          code
          name
          serviceClass
        }
        tariffClassCode
        tariffClassName
        property {
          regionName
          districtName
          blockName
          roundName
          plotCode
          itineraryCode
          code
          _id
        }
      }
    }
  }
`;

const orderTabs = [
  {
    name: "Customer Details",
    href: "customer",
  },
  {
    name: "Complaint Details",
    href: "complaint",
  },
];

export default function ViewCustomerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [orderTab, setOrderTab] = useState("complaint");
  const { data, loading } = useQuery(GET_COMPLAINT, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const __setOrderTab = (tab: string) => () => {
    setOrderTab(tab);
  };

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      hidePadding
      title="Complaint Information"
      size="5xl"
      description="Details of complaint are shown below"
    >
      <>
        <div className="block">
          <div className="border-b border-gray-200 bg-white px-6">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {orderTabs.map((_orderTab) => (
                <button
                  key={_orderTab.name}
                  onClick={wrapClick(__setOrderTab(_orderTab.href))}
                  className={classNames(
                    orderTab === _orderTab.href
                      ? "border-primary-500 text-primary-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                    "whitespace-nowrap disabled:cursor-not-allowed flex py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    orderTab === _orderTab.href ? "page" : undefined
                  }
                >
                  {_orderTab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        <div className="flex-1 w-full max-h-[85vh] overflow-y-auto  sm:p-6">
          {orderTab === "customer" && (
            <ServicePointView servicePoint={data?.complaint?.servicePoint} />
          )}
          {orderTab === "complaint" && (
            <ComplaintView complaint={data?.complaint} />
          )}
        </div>
      </>
    </Modal>
  );
}
