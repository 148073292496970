import { gql, useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { currentConfigVar } from "apollo/cache/config";
import { Shimmers, TableComponent } from "components";
import { Action } from "components/buttons/action-button";
import { Avatar } from "components/core";
import config from "config";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  classNames,
  getPeriod,
  useDownloadFile,
  useTableData,
  wrapClick
} from "utils";
import { GET_REPLACEMENT_SERVICE_ORDERS } from "./hooks";
const DATE_FORMAT = "YYYY-MM-DD";

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query(
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: ReplacementServiceOrderStatus
    $statuses: [ReplacementServiceOrderStatus]
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $fileName: String
  ) {
    url: getReplacementExportUrl(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      fileName: $fileName
    )
  }
`;

const ReplacementServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "_HasleFree.xlsx",
  });
  const handleDownloadItem = () => {
    createDataExport({
      variables: filter,
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.url) {
        downloadAction(`${data.url}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "servicePointCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
      ],
      sort: searchParams.sort || "completedAt",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: "Completed",
      dateField: "completedAt",
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
      fileName: `Replacement Report For ${getPeriod(searchParams)}`,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_REPLACEMENT_SERVICE_ORDERS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: true,
    }
  );

  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "completedAt" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        id: undefined,
      }),
    });
  }, [searchParams.orderStatus, navigate]);

  const records = useTableData(data || {});

  return (
    <>
      <div className='flex flex-1 overflow-y-auto'>
        <div className='flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex'>
          <TableComponent
            title={"replacements"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            defaultSearchField='code'
            renderHeaderItems={() => (
              <div className='mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2'>
                <button
                  type='button'
                  onClick={wrapClick(handleDownloadItem)}
                  disabled={gettingUrl || downloadLoading}
                  className='ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'
                >
                  <CloudArrowDownIcon
                    className='-ml-0.5 mr-2 h-4 w-4'
                    aria-hidden='true'
                  />
                  {gettingUrl
                    ? "Processing file for download"
                    : downloadLoading
                    ? "Exporting"
                    : "Export To Excel"}
                </button>
                {/* <SearchSelectInput
                  id="status"
                  labelHidden={true}
                  options={[
                    { label: { title: "Low" }, value: "Low" },
                    { label: { title: "Medium" }, value: "Medium" },
                    { label: { title: "High" }, value: "High" },
                    { label: { title: "Critical" }, value: "Critical" },
                  ]}
                  label={""}
                  placeholder="Select Status"
                  setFieldValue={(_: any, value: string) => setStatus(value)}
                  values={{ status }}
                  setFieldTouched={undefined}
                  setFieldError={undefined}
                /> */}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Code | Date
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Region | District
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Customer
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Location
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Type
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  ServicePoint
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Old Meter
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  New Meter
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Resolution Period
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Assigner
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Assignee
                </th>
                <th
                  scope='col'
                  className='sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'
                >
                  Replacement Reason
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.SingleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.DoubleShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.AvatarShimmer />
                </td>
                <td className='px-6 py-4 border-b border-gray-200 print:border-gray-700'>
                  <Shimmers.SingleShimmer />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className='hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer'
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 '>
                  <div className='font-medium text-gray-900 dark:text-gray-100'>
                    {item?.code || "N/A"}
                  </div>
                  <div className='font-medium text-gray-500 dark:text-gray-400'>
                    {moment(item?.createdAt).format(dateFormat)}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.region?.name || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.district?.name || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.location?.address || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.location?.community || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.replacementType === "High"
                        ? `bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-100`
                        : "",
                      item?.replacementType === "Low"
                        ? `bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50`
                        : "",
                      item?.replacementType === "Critical"
                        ? `bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-100`
                        : "",
                      item?.replacementType === "Medium"
                        ? `bg-yellow-100 text-yellow-800 dark:bg-yello-600 dark:text-yellow-100`
                        : "",
                      !item?.replacementType
                        ? "bg-gray-200 text-gray-800 dark:bg-gray-600 dark:text-gray-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>
                      {_.startCase(item?.replacementType) || "Unknown"}
                    </span>
                  </span>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.servicePoint?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.servicePoint?.geoCode || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.oldMeterNumber || item?.meter?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.meter?.modelMeta?.brandName || "N/A"} -{" "}
                    {item?.meter?.modelMeta?.modelName || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    {item?.installedMeter?.code || "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-500'>
                    {item?.installedMeter?.modelMeta?.brandName || "N/A"} -{" "}
                    {item?.installedMeter?.modelMeta?.modelName || "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right'>
                  <div className='text-gray-900 dark:text-gray-100'>
                    <span className='text-xs mr-2 font-light'>from</span>
                    {item?.assignedAt
                      ? moment(item?.assignedAt).format(dateFormat)
                      : "N/A"}
                  </div>
                  <div className='text-gray-500 dark:text-gray-400'>
                    <span className='text-xs mr-2 font-light'>to</span>
                    {item?.completedAt
                      ? moment(item?.completedAt).format(dateFormat)
                      : "N/A"}
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 '>
                  <div className='flex items-center'>
                    <div className='h-10 w-10 flex-shrink-0'>
                      <Avatar
                        disabled={true}
                        alt={
                          [
                            (item?.assigner?.lastName || "")?.trim(),
                            (item?.assigner?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"
                        }
                        src={item?.assigner?.profileImageUrl || ""}
                      />
                    </div>
                    <div className='ml-4'>
                      <div className='text-gray-900 dark:text-gray-100'>
                        {[
                          (item?.assigner?.lastName || "")?.trim(),
                          (item?.assigner?.firstName || "")?.trim(),
                        ]
                          .join(" ")
                          .trim() || "N A"}
                      </div>
                      <div className='text-gray-500 dark:text-gray-400'>
                        {item?.assigner?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 '>
                  {item.assigneeType === "MeterManufacturingContractor" ? (
                    <div className='flex items-center'>
                      <div className='h-10 w-10 flex-shrink-0'>
                        <Avatar
                          disabled={true}
                          alt={item?.meterContractor?.name || "N A"}
                        />
                      </div>
                      <div className='ml-4'>
                        <div className='text-gray-900 dark:text-gray-100'>
                          {item?.meterContractor?.name || "N/A"}
                        </div>
                        <div className='text-gray-500 dark:text-gray-400'>
                          Meter Manufacturing Contractor
                        </div>
                      </div>
                    </div>
                  ) : item.assigneeType === "FarmingOutContractor" ? (
                    <div className='flex items-center'>
                      <div className='h-10 w-10 flex-shrink-0'>
                        <Avatar
                          disabled={true}
                          alt={item?.farmingOutContractor?.name || "N A"}
                        />
                      </div>
                      <div className='ml-4'>
                        <div className='text-gray-900 dark:text-gray-100'>
                          {item?.farmingOutContractor?.name || "N/A"}
                        </div>
                        <div className='text-gray-500 dark:text-gray-400'>
                          Farming Out Contractor
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='flex items-center'>
                      <div className='h-10 w-10 flex-shrink-0'>
                        <Avatar
                          disabled={true}
                          alt={
                            [
                              (item?.assignee?.lastName || "")?.trim(),
                              (item?.assignee?.firstName || "")?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"
                          }
                          src={item?.assignee?.profileImageUrl || ""}
                        />
                      </div>
                      <div className='ml-4'>
                        <div className='text-gray-900 dark:text-gray-100'>
                          {[
                            (item?.assignee?.lastName || "")?.trim(),
                            (item?.assignee?.firstName || "")?.trim(),
                          ]
                            .join(" ")
                            .trim() || "N A"}
                        </div>
                        <div className='text-gray-500 dark:text-gray-400'>
                          {item?.assignee?.phoneNumber || "N/A"}
                        </div>
                      </div>
                    </div>
                  )}
                </td>
                <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700'>
                  <span>{item?.replacementReason || "N/A"}</span>
                </td>
              </tr>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default ReplacementServiceOrdersPage;
